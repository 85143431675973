// import { DataSources } from '../constants/dataSources';
import { baseMap } from './common/common';
import { DemoId, WeatherLayersId } from '../constants/sidebar';
import { initializeLayersPanel } from '../utils/layers';
import { Impacts } from './common/impacts';
import { defaultOverrides, radarButtonGL, alertsButtonGL } from './common/mapsGL';
import { radarButton, alertsButton, satelliteButton } from './common/buttons';
import { OtherExamplesUrl } from '../constants/urls';
import { DataSources } from '../constants/dataSources';

let _aeris;
let lastCoord;
const map = {
    ...baseMap,
    map: {
        ...baseMap.map,
        zoom: 9
    }
};

const mapsGLConfig = {
    layers: [
        {
            title: 'Conditions',
            buttons: [
                radarButtonGL,
                {
                    title: 'Road Weather',
                    value: 'road-weather',
                    segments: [{
                        title: 'US/CA Surface Conditions',
                        value: 'road-weather-surface-us'
                    }, {
                        title: 'US/CA Travel Summary',
                        value: 'road-weather-summary-us'
                    }, {
                        title: 'EU Travel Conditions',
                        value: 'road-weather-surface-europe'
                    }, {
                        title: 'EU Travel Summary',
                        value: 'road-weather-summary-europe'
                    },]
                },
                {
                    title: 'Winds',
                    value: 'wind',
                    options: {
                        ...defaultOverrides,
                        paint: {
                            sample: {
                                colorscale: {
                                    normalized: true,
                                    stops: [
                                        0, '#fff',
                                        1, '#fff'
                                    ]
                                }
                            },
                            particle: {
                                size: 1,
                            }
                        }
                    },
                    segments: [{
                        title: 'Particles', value: 'wind-particles'
                    },
                    { title: 'Barbs', value: 'wind-barbs' }]
                }]
        },
        {
            title: 'Severe',
            buttons: [
                alertsButtonGL,
                {
                    title: "Lightning Strikes",
                    value: "lightning-strikes"
                },
                {
                    id: 'stormcells',
                    title: 'Storm Cells',
                    value: 'stormcells',
                    options: {
                        data: {
                            evaluator: {
                                title: 'Storm Cells',
                                fn: (value) => {
                                    if (value.length === 1) {
                                        return 'storm approaching!';
                                        // return title(value[0].traits.type) + ' storm approaching!';
                                    }
                                    else if (value.length > 1) {
                                        return value.length + ' storms approaching!';
                                    }
                                    return 'No storms';
                                },
                            },
                        },
                    }
                }
            ]
        }]
};

const rasterLayers = [
    {
        title: 'Conditions',
        buttons: [
            radarButton,
            {
                title: 'Road Weather',
                value: 'road-weather',
                segments: [{
                    title: 'Road Conditions',
                    value: 'road-conditions'
                }, {
                    title: 'Road Conditions Index',
                    value: 'road-conditions-index'
                }]
            },
        ]
    },
    {
        title: 'Severe',
        buttons: [
            alertsButton,
            {
                title: "Lightning Strikes",
                value: "lightning-strikes"
            },
            {
                id: 'stormcells',
                title: 'Storm Cells',
                value: 'stormcells',
            }
        ]
    }];

export const RoutingConfig = ({ weatherLayers, layers, sidebarControls }) => ({
    app: {
        ...map,
        mode: 'light',
        panels: {
            layers: {
                toggleable: true,
                buttons: [
                    // {
                    //     title: 'Waterways',
                    //     value: 'waterways'
                    // },
                    {
                        title: 'Satellite',
                        buttons: [
                            satelliteButton
                        ]
                    },
                    ...(weatherLayers === 'raster' ? rasterLayers : [])
                ]
            },
            timeline: {
                toggleable: true,
                range: {
                    value: [-4, 0],
                    range: {
                        min: -48,
                        max: 0
                    },
                    marksEvery: '% 12',
                    step: 2
                }
            },
            info: {
                views: {
                    localweather: {
                        request: (data) => {
                            const request = _aeris.api();
                            const forecastFields = 'timestamp,dateTimeISO,tempF,icon,weatherPrimary,windSpeedMPH,windSpeedMinMPH,windSpeedMaxMPH,windGustMPH,snowIN,precipIN, maxTempC, minTempC, maxTempF, minTempF'
                                .split(',')
                                .map((key) => `periods.${key}`);
                            request.addRequest(
                                _aeris.api().endpoint('forecasts').fields(forecastFields.join(',')).filter('1day')
                                    .limit(7)
                            );
                            request.addRequest(
                                _aeris.api().endpoint('threats').radius('50mi')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('conditions')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('alerts').fields('details.type,details.name,timestamps')
                            );
                            request.addRequest(_aeris.api().endpoint(
                                'phrases/summary'
                            ));
                            request.addRequest(
                                _aeris.api().endpoint('convective/outlook').action('contains')
                            );
                            request.addRequest(
                                _aeris.api().endpoint('lightning/summary').action('closest').radius('60mi')
                                    .limit(100)
                            );
                            return request;
                        },
                        views: [{
                            data: (data) => {
                                if (_aeris.utils.isEmpty(data)) return null;
                                return data;
                            },
                            renderer: (data) => {
                                if (!data) {
                                    return '';
                                }
                                return Impacts({ demo: DemoId.routing, lastCoord });
                            }
                        },
                        {
                            title: 'Active Alerts',
                            renderer: 'alerts'
                        }, {
                            title: 'Active Threats',
                            renderer: 'threats'
                        }, {
                            title: 'Current Conditions',
                            renderer: 'obs'
                        }, {
                            title: 'Outlook',
                            renderer: 'outlook'
                        }]
                    }
                }
            }
        }
    },
    events: {
        ready: (aeris, app) => {
            _aeris = aeris;
            app.map.on('click', (e) => {
                lastCoord = e.data.coord;
                app.showInfoAtCoord(e.data.coord, 'localweather', 'Local Weather');
            });

            // app.addSource('waterways', 'geojson', {
            //     data: {
            //         url: () => '/data/routing/sea/waterways/gulf-waterways.geojson',
            //         coordinate: ({ lat, lon }) => ({ lat, lon })
            //     },
            //     style: {
            //         polyline: {
            //             stroke: {
            //                 color: theme === 'dark' || theme === 'satellite' ? '#b0adc7' : '#727080',
            //                 width: 2,
            //             },
            //         },
            //     }
            // });

            aeris.modules().then((modules) => {
                app.modules.insertAt(0, modules.Routing, null, {
                    id: 'trucking-routes',
                    title: 'Trucking Routes',
                    url(value) {
                        return `${DataSources.routing.trucking}${value.replace(/^.*route-/, '')}.json`;
                    },
                    onRouteData(data) {
                        sidebarControls.onSidebarClose();

                        // set the asset's current location to the route coords index that corresponds to the
                        // current GMT hour for demo purposes

                        if (data.asset && data.feature) {
                            const coords = data.feature.coordinates;
                            const hour = new Date().getUTCHours();
                            const count = coords.length - 2;
                            const index = 1 + Math.min(Math.floor((count / 23) * hour), coords.length);
                            const prevIndex = Math.max(0, index - 1);

                            // determine if asset is moving west-to-east or east-to-west and use
                            // the appropriate icon
                            const coord = coords[index];
                            const prevCoord = coords[prevIndex];
                            let isLeftToRight = true;

                            if (coord.lat < prevCoord.lat) {
                                isLeftToRight = false;
                            }

                            data.current = {
                                loc: coords[index],
                                markerStyle: {
                                    className: 'route-asset',
                                    icon: {
                                        url: isLeftToRight ? `${OtherExamplesUrl}map-app/assets/icons/icon-route-asset.svg` : `${OtherExamplesUrl}map-app/assets/icons/icon-route-asset-reversed.svg`
                                    },
                                    size: [24, 24]
                                }
                            };
                            data.asset.loc = data.current.loc;
                        }
                        return data;
                    },
                    routes: [{
                        id: 'route-chicago-charlotte',
                        title: 'Route 1'
                    }, {
                        id: 'route-los_angeles-philadelphia',
                        title: 'Route 2'
                    }, {
                        id: 'route-denver-seattle',
                        title: 'Route 3'
                    }, {
                        id: 'route-richmond-boston',
                        title: 'Route 4'
                    }, {
                        id: 'route-houston-atlanta-memphis-cincinnati',
                        title: 'Route 5'
                    }, {
                        id: 'route-toronto-vancouver',
                        title: 'Route 6'
                    }]
                });

                // app.modules.insertAt(2, modules.Routing, null, {
                //     id: 'gulf-stream-routes',
                //     title: 'Gulf Stream Routes',
                //     value: '',
                //     url(value) {
                //         return `/data/routing/sea/waterways/${value}.json`;
                //     },
                //     routes: [{
                //         id: 'route-1-waterway',
                //         title: 'Route 1'
                //     }, {
                //         id: 'route-2-waterway',
                //         title: 'Route 2'
                //     }, {
                //         id: 'route-3-waterway',
                //         title: 'Route 3'
                //     }, {
                //         id: 'route-4-waterway',
                //         title: 'Route 4'
                //     }]
                // });
                // app.modules.insertAt(3, modules.Routing, null, {
                //     id: 'shipping',
                //     title: 'Transatlantic Service',
                //     value: '',
                //     url(value) {
                //         return `/data/routing/sea/shippingLanes/${value}.json`;
                //     },
                //     routes: [{
                //         id: 'route-1-shipping',
                //         title: 'Route 1'
                //     }, {
                //         id: 'route-2-shipping',
                //         title: 'Route 2'
                //     }, {
                //         id: 'route-3-shipping',
                //         title: 'Route 3'
                //     }, {
                //         id: 'route-4-shipping',
                //         title: 'Route 4'
                //     },
                //     {
                //         id: 'route-5-shipping',
                //         title: 'Route 5'
                //     },
                //     {
                //         id: 'route-6-shipping',
                //         title: 'Route 6'
                //     },
                //     {
                //         id: 'route-7-shipping',
                //         title: 'Route 7'
                //     }]
                // });
                setTimeout(() => {
                    initializeLayersPanel(aeris, app, { demo: DemoId.routing, weatherLayers }, { mapsGLConfig, layersFromParams: layers }).then(({ controller }) => {
                        if (controller) {
                            app.on('layer:deselect', (e) => {
                                const { id } = e.data;
                                if (id === 'stormcells' && weatherLayers === WeatherLayersId.mapsGL) {
                                    controller.removeWeatherLayer('stormcells');
                                }
                            });
                        }
                    });
                }, 250);
            });
        }
    }
});
